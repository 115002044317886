// Global

.site-navbar {
    z-index: 100;
    background-color: transparentize($mantle, 0.9);
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);

    .dropdown-menu {
        background-color: transparentize($mantle, 0.9);
        backdrop-filter: blur(25px);
        -webkit-backdrop-filter: blur(25px);
    }

    .navbar-brand:hover {
        .text-body-emphasis {
            color: $primary !important;
        }
    }
}

.navbar-desktop {
    margin-left: 15%;
    margin-right: 15%;
}

.bg-body-mantle {
    background-color: $mantle;
}

.shadow-crust {
    box-shadow: 0 1rem 3rem rgba($crust, .15);
}

.navbar-toggler {
    margin-left: auto;
    margin-right: auto;
}

.text-primary-glow {
    text-shadow: 0 0 2rem transparentize($primary, .5);
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-10px);} 
    60% {transform: translateY(-5px);} 
} 

.bounce {
    animation: bounce 2s infinite;
}

.feature-icon {
    width: 4rem;
    height: 4rem;
    border-radius: .75rem;
}

.icon-link > .bi {
    margin-top: -.3rem;
}

.scroll-offset {
    scroll-margin-top: 9rem;
}

// Post

article {
    h1, h2, h3, h4, h5, h6 {
        @extend .scroll-offset;
    }
    
    img {
        @extend .rounded;
        width: 100%;
        height: auto;
    }
}

// Homepage
.site-hero {
    padding-top: 150px;
    padding-bottom: 75px;
}

.width-700 {
    max-width: 700px;
}

.hero-bloom {
    overflow: hidden;
    background-image: radial-gradient(farthest-side at center top, transparentize(#4228de, 0.75), transparentize(#5e3cf5, 1));
    -webkit-mask-image: linear-gradient(180deg, #000 95%, transparent);
    mask-image: linear-gradient(180deg, #000 95%, transparent);
}

.stars {
    position: fixed;
    top: -50vh;
    // left: 0;
}

.project-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100%;
    height: 100%;
}

.card-hover {
    transition: transform 0.3s;
}

.card-hover:hover {
    transform: translateY(-15px) scale(1.025);
}

.card-hover:active {
    transform: translateY(15px) scale(0.975);
}