$white: #1e1e2e;
$black: #cdd6f4;
$blue: #89b4fa;
$indigo: #b4befe;
$purple: #cba6f7;
$pink: #f5c2e7;
$red: #f38ba8;
$orange: #fab387;
$yellow: #f9e2af;
$green: #a6e3a1;
$teal: #94e2d5;
$cyan: #89dceb;
$gray-900: #cdd6f4;
$gray-800: #bac2de;
$gray-700: #a6adc8;
$gray-600: #9399b2;
$gray-500: #7f849c;
$gray-400: #6c7086;
$gray-300: #585b70;
$gray-200: #45475a;
$gray-100: #313244;
$primary: #cba6f7;
$yiq-text-dark: $white;
$yiq-text-light: $black;
$table-dark-color: $black;
$kbd-bg: $white;
$kbd-color: $black;

// $theme-colors: map-merge($theme-colors, (
//     "white": $white,
//     "black": $black,
//     "blue": $blue,
//     "indigo": $indigo,
//     "purple": $purple,
//     "pink": $pink,
//     "red": $red,
//     "orange": $orange,
//     "yellow": $yellow,
//     "green": $green,
//     "teal": $teal,
//     "cyan": $cyan,
//     "gray-900": $gray-900,
//     "gray-800": $gray-800,
//     "gray-700": $gray-700,
//     "gray-600": $gray-600,
//     "gray-500": $gray-500,
//     "gray-400": $gray-400,
//     "gray-300": $gray-300,
//     "gray-200": $gray-200,
//     "gray-100": $gray-100,
//     "primary": $primary,
// ))